.post-box {
    background: #fff;
    padding: 0;
    margin-bottom: 45px;
    transition: all 0.2s ease;
    .post-box-image {
        //height: 250px;
        width: 100%;
        text-align: center;
        img {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
    .post-date {
        position: absolute;
        top: -13px;
        left: -61px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $orange;
        padding: 12px 0;
        box-shadow: 1px 1px 2px 0 rgba(130, 46, 3, 0.87);
        span {
            display: block;
            text-align: center;
            line-height: 15px;
            font-weight: 700;
            color: #fff;
        }
    }
    .post-box-header {
        .title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 10px;
            color: #333;
            &:hover {
                color: $blue;
            }
        }
    }
    .post-box-info {
        margin-bottom: 15px;
        .info-item {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            font-style: italic;
        }
    }
    .post-box-body {
        position: relative;
        p {

        }
    }
}