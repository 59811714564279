.content-box {
    background: #fff;
    box-shadow: 1px 2px 6px #b6b6b6;
    padding: 15px;
    margin-bottom: 30px;
    .content-box-heading {
        margin-left: -15px;
        margin-right: -15px;
        padding: 0 15px 15px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 15px;
        h3 {
            font-size: 22px;
            line-height: 1; 
        }
    }
} 