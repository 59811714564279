.article-box {
  
}
.article-box-heading {
  h1 {
    margin-bottom: 25px;
  }
}
.article-box-body {
  ul, ol {
    li {
      font-size: 16px;
    }
  }
  .aligncenter {
    display: block;
    margin: 0 auto 15px;
  }
}