.header {
    .navbar {
        background: $blue;
        border-radius: 0;
        border: none;
        border-bottom: 3px solid $blue-dark;
        margin-bottom: 0;
        .navbar-brand {
            //font-family: 'Merriweather', serif;
            color: #fff;
        }
        .navbar-nav {
            //font-family: 'Merriweather', serif;
            li {
                &.current-menu-item {
                    a {
                        background: darken($blue, 5%);
                    }
                }
                a {
                    color: #fff;
                    font-size: 12px;
                    text-transform: uppercase;
                    &:hover {
                        background: darken($blue, 5%);
                    }
                }
            }
        }
    }

}
