@import "_variables.scss";
@import "_mixins.scss";

html {

}

img {
    max-width: 100%;
    height: auto;
}

body {
    font-size: 14px;
    color: #333;
    background: #fff;
    font-family: 'Play', sans-serif;
}

* {
    outline: none !important;
}