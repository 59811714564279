.form-control {
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    &:focus {
        box-shadow: none;
        border-color: $broun;
    }
}
.form-control-feedback {
    height: 40px;
    line-height: 40px;
}