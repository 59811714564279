
/***********************/
/*      BASE STYLES    */

@import "base/_base.scss";

/***********************/
/*        LAYOUT       */

// HEADER
@import "layout/_header.scss"; 

// CONTENT
@import "layout/_content.scss";

//FOOTER 
@import "layout/_footer.scss";



/***********************/
/*       MODULES       */
@import "modules/_content-box.scss";

@import "modules/typography";
@import "modules/article-box";
@import "modules/post-box";

/* Sidebar widget */
@import "modules/sidebar-widget";

/* Forms */
@import "modules/forms";



/***********************/
/*        PAGES        */




/***********************/
/*        MEDIA        */

@import "_media.scss";