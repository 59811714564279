.sidebar-widget {
  margin-bottom: 50px;
  &:hover {
    
  }
  .widget-heading {
    .widget-title {
      color: $broun;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
      position: relative;
      display: inline-block;
      padding-left: 20px;
      padding-right: 20px;
      &:before, &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 10px;
        height: 2px;
        background: #555;
      }
      &:after {
        right: 0;
        left: auto;
      }
    }
  }
  > ul {
    margin: 0;
    padding: 0 0 0 20px;
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border-bottom: 1px solid #eeeeee;
      &:last-child {
        border-bottom: none;
      }
      &.active {
        a {
          color: $broun;
          &:before {
            width: 100%;
            transition: all 0.3s ease-out;
          }
        }
      }
      a {
        display: block;
        padding: 15px 0;
        line-height: 20px;
        color: #333333;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 2px;
          width: 0;
          transition: all 0.6s ease-out;
          background: $orange;
        }
        &:hover {
          color: $broun;
          &:before {
            width: 100%;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }
  .widget-body {
    padding-left: 20px;
    .widget-list {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-bottom: 1px solid #eeeeee;
        &:last-child {
          border-bottom: none;
        }
        &.active {
          a {
            color: $broun;
            &:before {
              width: 100%;
              transition: all 0.3s ease-out;
            }
          }
        }
        a {
          display: block;
          padding: 15px 0;
          line-height: 20px;
          color: #333333;
          font-weight: 700;
          font-size: 14px;
          text-decoration: none;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 0;
            transition: all 0.6s ease-out;
            //background: $broun;
            background: $orange;
          }
          &:hover {
            color: $broun;
            &:before {
              width: 100%;
              transition: all 0.3s ease-out;
            }
          }
        }
      }
    }
  }
}

.recent-posts {
  .recent-item {
    margin-bottom: 15px;
    a {
      text-decoration: none;
    }
    &:hover {
      .item-image {
        img {
          transform: scale(1.2) rotate(0deg);
        }
      }
      .item-title {
        padding-left: 20px;
        &:before {
          opacity: 1;
          transition-delay: 0.15s;
        }
      }
    }
    .item-image {
      height: 130px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-out;
        transform: scale(1.5) rotate(10deg);
      }
    }
    .item-title {
      line-height: 20px;
      font-size: 13px;
      text-transform: uppercase;
      color: #333;
      padding: 5px 0;
      font-weight: bold;
      text-decoration: none;
      transition: all 0.15s ease-out;
      position: relative;
      &:before {
        content: "\f06e";
        font-family: FontAwesome, sans-serif;
        color: $broun;
        transition: all 0.15s ease-out;
        opacity: 0;
        position: absolute;
        left: 0;
      }
    }
  }
}

.search-widget {
  .form-group {
    .form-control {
      &:focus + .form-control-feedback {
        color: $broun;
      }
    }
    .form-control-feedback {
      color: #ADADAD;
    }
  }
}